export const asYesString = (value?: string): boolean =>
  (value && ['true', 'yes', 'ok', '1'].includes(value)) || false;

export const asMaybeString = <T extends string>(value?: string): T =>
  ((value && `${value}`) || '') as T;

export const asMaybeNumber = <T extends number>(value?: string): T =>
  ((value && Number(`${value}`)) || 0) as T;

const STAGE = asMaybeString(process.env.STAGE);
const DEPLOY_LOCAL = asYesString(`${process.env.DEPLOY_LOCAL}`);

export const Config = {
  STAGE,
  DEPLOY_LOCAL,

  APPLICATION_FEATURE_USE_DEVLAUNCHER: STAGE !== 'prod',
  APPLICATION_FEATURE_USE_MARKINGS: STAGE !== 'prod' && !!DEPLOY_LOCAL,

  APPLICATION_LOCALSTORAGE_PREFIX: asMaybeString(
    process.env.APPLICATION_LOCALSTORAGE_PREFIX
  ),
  APPLICATION_DISPLAY_NAME: asMaybeString(process.env.APPLICATION_DISPLAY_NAME),
  APPLICATION_LONG_DISPLAY_NAME: asMaybeString(
    process.env.APPLICATION_LONG_DISPLAY_NAME
  ),
  APPLICATION_SHORT_DISPLAY_NAME: asMaybeString(
    process.env.APPLICATION_SHORT_DISPLAY_NAME
  ),
  APPLICATION_VERSION: asMaybeString(process.env.APPLICATION_VERSION),

  /**
   * Apollo Client
   */
  APPLICATION_APOLLO_CLIENT_NAME: asMaybeString(
    process.env.APPLICATION_APOLLO_CLIENT_NAME
  ),
  // operation prefix name, because some people like to read this in raw logs  (Only use [a-zA-Z_])
  APPLICATION_APOLLO_CLIENT_OPERATIONPREFIX_SHORTNAME: asMaybeString(
    process.env.APPLICATION_APOLLO_CLIENT_OPERATIONPREFIX_SHORTNAME
  ),
  APPLICATION_APOLLO_CLIENT_API_URL: asMaybeString(
    process.env.APPLICATION_APOLLO_CLIENT_API_URL
  ),

  /**
   * Reckon Services
   */
  APPLICATION_RECKON_APP_LAUNCHER_URL: asMaybeString(
    process.env.APPLICATION_RECKON_APP_LAUNCHER_URL
  ),
  APPLICATION_DEADLY_DIGITS_APP_LAUNCHER_URL: asMaybeString(
    process.env.APPLICATION_DEADLY_DIGITS_APP_LAUNCHER_URL
  ),

  /**
   * Third Party Services
   */
  APPLICATION_INTERCOM_APPNAME: asMaybeString(
    process.env.APPLICATION_INTERCOM_APPNAME
  ),
  APPLICATION_SENTRY_DSN: asMaybeString(process.env.APPLICATION_SENTRY_DSN),
  APPLICATION_GOOGLE_GTM_APIKEY: asMaybeString(
    process.env.APPLICATION_GOOGLE_GTM_APIKEY
  ),
  APPLICATION_GOOGLE_GOOGLE_ANALYTICS_KEY: asMaybeString(
    process.env.APPLICATION_GOOGLE_GOOGLE_ANALYTICS_KEY
  ),
  APPLICATION_BRANCHIO_APIKEY: asMaybeString(
    process.env.APPLICATION_BRANCHIO_APIKEY
  ),
  APPLICATION_HEAPIO_APPID: asMaybeString(process.env.APPLICATION_HEAPIO_APPID),
  APPLICATION_INTERCOM_APPID: asMaybeString(
    process.env.APPLICATION_INTERCOM_APPID
  ),
  APPLICATION_APPCUES_APPID: asMaybeString(
    process.env.APPLICATION_APPCUES_APPID
  ),
  MISSING_PRODUCT_HELP_URL: asMaybeString(process.env.MISSING_PRODUCT_HELP_URL),

  APPLICATION_RECKON_PORTAL_URL: asMaybeString(
    process.env.APPLICATION_RECKON_PORTAL_URL
  ),
  APPLICATION_DEADLYDIGITS_PORTAL_URL: asMaybeString(
    process.env.APPLICATION_DEADLYDIGITS_PORTAL_URL
  ),
  APPLICATION_HELP_AND_SUPPORT_URL: asMaybeString(
    process.env.APPLICATION_HELP_AND_SUPPORT_URL
  ),
} as const;
