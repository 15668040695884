/** @jsx jsx */

import { DataAttributeMap, buildDataAttributes, jsx } from '@balance-web/core';
import { forwardRefWithAs } from '@balance-web/utils';

import { BoxStyleProps, useBoxStyles } from './useBoxStyles';

export type BoxProps = {
  data?: DataAttributeMap;
  id?: string;
} & BoxStyleProps;

export const Box = forwardRefWithAs<'div', BoxProps>(
  ({ as: Tag = 'div', ...props }, ref) => {
    const {
      // box props
      id,
      data,

      // style props
      alignSelf,
      background,
      border,
      borderRadius,
      borderWidth,
      bottom,
      boxShadow,
      cursor,
      flex,
      flexBasis,
      flexGrow,
      flexShrink,
      height,
      left,
      margin,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      marginX,
      marginY,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      overflow,
      overflowX,
      overflowY,
      padding,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingX,
      paddingY,
      position,
      right,
      top,
      userSelect,
      width,
      zIndex,
      ...attrs
    } = props;

    const boxStyles = useBoxStyles({
      alignSelf,
      background,
      border,
      borderRadius,
      borderWidth,
      bottom,
      boxShadow,
      cursor,
      flex,
      flexBasis,
      flexGrow,
      flexShrink,
      height,
      left,
      margin,
      marginBottom,
      marginLeft,
      marginRight,
      marginTop,
      marginX,
      marginY,
      maxHeight,
      maxWidth,
      minHeight,
      minWidth,
      overflow,
      overflowX,
      overflowY,
      padding,
      paddingBottom,
      paddingLeft,
      paddingRight,
      paddingTop,
      paddingX,
      paddingY,
      position,
      right,
      top,
      userSelect,
      width,
      zIndex,
    });

    return (
      <Tag
        css={boxStyles}
        ref={ref}
        id={id}
        {...(data ? buildDataAttributes(data) : undefined)}
        {...attrs}
      />
    );
  }
);

Box.displayName = 'Box';
