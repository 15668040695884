import React, { ReactNode, createContext, useContext } from 'react';
import {
  useElevationModifier,
  useMaxElevationModifier,
} from '@balance-web/elevate';
import { typedEntries } from '@balance-web/utils';

import type { BalanceTheme, BalanceThemeRaw, PaletteSignature } from './types';

// theme context

type ThemeContextType = {
  theme: BalanceTheme;
  themeRaw: BalanceThemeRaw;
  themeCSSVars: Record<string, string>;
  palette: BalanceTheme['palette'];
  paletteResolver: PaletteSignature;
};

export const ThemeContext = createContext<ThemeContextType | null>(null);

export type ThemeProviderProps = {
  theme: BalanceTheme;
  themeRaw: BalanceThemeRaw;
  themeCSSVars: Record<string, any>;
  paletteResolver?: PaletteSignature;
  children: ReactNode;
};

const useThemeContext = () => {
  const themeContext = useContext(ThemeContext);

  if (!themeContext) throw Error('Theme context not found');

  return themeContext;
};

export const ThemeProvider = ({
  theme,
  themeRaw,
  themeCSSVars,
  paletteResolver,
  children,
}: ThemeProviderProps) => {
  const resolvedPalette = paletteResolver
    ? paletteResolver(theme.colors)
    : theme.palette;

  return (
    <ThemeContext.Provider
      value={{
        theme,
        themeRaw,
        themeCSSVars,
        palette: resolvedPalette,
        paletteResolver: paletteResolver || (() => theme.palette),
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): BalanceTheme => {
  const { palette, theme, themeRaw } = useThemeContext();
  const elevationModifier = useElevationModifier();
  const maxElevationModifier = useMaxElevationModifier();

  // Be mindful elements that require relative vs absolute elevation when making changes here.
  const relativeElevationTokens = {
    card: themeRaw.elevation.card + elevationModifier,
    dropdown: themeRaw.elevation.dropdown + elevationModifier,
    modal: themeRaw.elevation.modal + elevationModifier,
    popover: themeRaw.elevation.popover + elevationModifier,
    sticky: themeRaw.elevation.sticky + elevationModifier,
  };

  // Toasts are rendered outside of React component tree and always shown on top so they require absolute elevation.
  const absoluteElevationTokens = {
    toast: themeRaw.elevation.toast + maxElevationModifier,
  };

  const modifiedElevation = {
    ...relativeElevationTokens,
    ...absoluteElevationTokens,
  };

  const elevation = typedEntries(modifiedElevation).reduce(
    (acc, [key, value]) => {
      acc[key] = value;
      return acc;
    },
    {} as Record<keyof typeof modifiedElevation, number>
  );

  return {
    ...theme,
    elevation,
    palette,
  };
};

export const useRawTheme = () => {
  const { themeRaw, themeCSSVars } = useThemeContext();
  return { ...themeRaw, themeCSSVars };
};
